import React from "react";
import hero2 from "../../images/slider/hero4-flower.png";
import bg from "../../images/slider/hero-bg22.png";
import heroImage from "../../images/default/hero-2.png";
import { Fade, Slide } from "react-awesome-reveal";

import TimeCountDown from "../countdown";
import { connect } from "react-redux";
import {
  addDaysToDate,
  getCloudinaryFullPath,
  getDayDiffFromToday,
} from "../../utils";
import moment from "moment";
import TypeWriter from "../TypeWriter/TypeWriter";

const Hero4 = ({
  brideName = "",
  groomName = "",
  eventDate = "",
  bannerPhoto,
  isPreview = false,
}) => {
  const brideFname = brideName.split(" ")[0];
  const groomFname = groomName.split(" ")[0];
  const daysLeft = getDayDiffFromToday(eventDate);

  const countDownDate = isPreview ? addDaysToDate(10) : eventDate;

  return (
    <section
      className="static-hero-s3 section"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <div className="hero-container">
        <div className="hero-inner">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-xl-6 col-lg-6 col-12">
                <div className="wpo-static-hero-inner">
                  <div className="slide-title">
                    <h2>
                      Save <span>the</span> Date
                    </h2>
                    <div className="shape">
                      <img src={hero2} alt="" />
                    </div>
                  </div>
                  <Fade
                    className="animate__animated animate__fadeInLeft"
                    delay={400}
                    duration={2000}
                    triggerOnce="true"
                  >
                    <div className="slide-sub-title">
                      <h3>
                        {groomFname} & {brideFname}
                      </h3>
                    </div>
                  </Fade>
                  <TypeWriter
                    weddingDate={moment(countDownDate).format("MMMM DD, YYYY")}
                  />

                  <div className="clearfix"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="static-hero-right">
        <div className="static-hero-img">
          <div className="static-hero-img-inner">
            <img
              className="hero4Image"
              src={getCloudinaryFullPath(bannerPhoto) || heroImage}
              alt=""
            />
          </div>
        </div>
        {countDownDate && (
          <div className="wpo-wedding-date">
            <div className="clock-grids">
              <Fade
                className="animate__animated animate__fadeInLeft"
                delay={400}
                duration={2000}
                triggerOnce="true"
              >
                <div id="clock">
                  <TimeCountDown weddingDate={countDownDate} />
                </div>
              </Fade>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    brideName: state.inviteDetails.invite?.bride?.name,
    groomName: state.inviteDetails.invite?.groom?.name,
    eventDate: state.inviteDetails.invite?.eventDate,
    bannerPhoto: state.inviteDetails.invite?.bannerPhoto,
    isPreview: state.inviteDetails?.isPreview,
  };
};
export default connect(mapStateToProps)(Hero4);
