export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export const replaceString = (str, replaceObj) => {
    for (let key in replaceObj) {
        if (replaceObj.hasOwnProperty(key)) {
            str = str.replace(`{${key}}`, replaceObj[key]);
        }
    }
    return str;
}

export const getHashtag = (bride, groom) => {
    return `#${groom?.split(" ")[0]}GoesTo${bride?.split(" ")[0]}`
}