import React from "react";
import { Slide } from "react-awesome-reveal";
import SectionTitle from "../SectionTitle";
// import sImg1 from "../../images/event/event-back.jpg";
// import sImg2 from "../../images/event/event-back-2.jpg";
// import sImg3 from "../../images/event/event-back-3.jpg";
import LocationMap from "./Modal";
import { connect } from "react-redux";
import moment from "moment";

// const images = [sImg1, sImg2, sImg3];

const EventSectionS3 = ({ events }) => {
  return (
    <section className="wpo-event-section-s3 section-padding" id="event">
      <div className="container">
        <SectionTitle subTitle={"Our Wedding"} MainTitle={"When & Where"} />
        <div className="wpo-event-wrap">
          <div className="row">
            {events.map((event, eitem) => (
              <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                <Slide
                  direction="up"
                  duration={1000 + eitem * 2}
                  triggerOnce="true"
                >
                  <div className="wpo-event-item">
                    <div className="wpo-event-img">
                      <div className="wpo-event-img-inner">
                        {/* <img src={sImg3} alt="" /> */}
                      </div>
                    </div>
                    <div className="wpo-event-text">
                      <div className="title">
                        <h2>{event.name}</h2>
                      </div>
                      <ul>
                        <li>
                          {moment(event?.dateAndTime).format(
                            "dddd, DD MMMM YYYY"
                          )}{" "}
                          <br /> {moment(event?.dateAndTime).format("hh:mm A")}{" "}
                          Onwards
                        </li>
                        <li>{event.theme}</li>
                        <li>{event.venue}</li>
                        {/* <li>
                            <LocationMap />
                          </li> */}
                      </ul>
                    </div>
                  </div>
                </Slide>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    events: state.inviteDetails.invite?.events,
  };
};

export default connect(mapStateToProps)(EventSectionS3);
