import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { Fade, Slide } from "react-awesome-reveal";
import SectionTitle from "../SectionTitle";
import { connect } from "react-redux";
import ContactForm from "../ContactFrom/ContactForm";
import { Link } from "react-router-dom";
// import bg from "../../images/event/venue.jpg";

const VenueSection = ({ venue = {} }) => {
  const { address, latitude, longitude, phone } = venue;
  const [directionLink, setDirectionLink] = useState("");

  useEffect(() => {
    AOS.init();
  }, []);

  const TransportOpt = [
    {
      title: "Fly",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="h-6 w-6 text-gray-500 dark:text-gray-400"
        >
          <path d="M17.8 19.2 16 11l3.5-3.5C21 6 21.5 4 21 3c-1-.5-3 0-4.5 1.5L13 8 4.8 6.2c-.5-.1-.9.1-1.1.5l-.3.5c-.2.5-.1 1 .3 1.3L9 12l-2 3H4l-1 1 3 2 2 3 1-1v-3l3-2 3.5 5.3c.3.4.8.5 1.3.3l.5-.2c.4-.3.6-.7.5-1.2z"></path>
        </svg>
      ),
      description:
        "For our out-of-town guests, we recommend booking your flights early to get the best rates. Click the button below to find flights that suit your schedule.",
      //  Book your flights to the nearest airport, which is about 15
      //  miles from the venue. We recommend arriving at least 2 hours
      //  before the event starts.
      link: `/data=!3m1!4b1!4m2!4m1!3e4`,
    },
    {
      title: "Train",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="h-6 w-6 text-gray-500 dark:text-gray-400"
        >
          <path d="M2 17 17 2"></path>
          <path d="m2 14 8 8"></path>
          <path d="m5 11 8 8"></path>
          <path d="m8 8 8 8"></path>
          <path d="m11 5 8 8"></path>
          <path d="m14 2 8 8"></path>
          <path d="M7 22 22 7"></path>
        </svg>
      ),
      description:
        // The nearest train station is just a 10-minute walk from the venue. Trains run every 30 minutes, so you can
        // easily plan your arrival.
        "All aboard! Plan your train journey in advance to ensure a smooth ride to our wedding. Click below to find the best routes and reserve your seats.",
      link: `/data=!3m1!4b1!4m2!4m1!3e3`,
    },
    {
      title: "Drive",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="h-6 w-6 text-gray-500 dark:text-gray-400"
        >
          <path d="M19 17h2c.6 0 1-.4 1-1v-3c0-.9-.7-1.7-1.5-1.9C18.7 10.6 16 10 16 10s-1.3-1.4-2.2-2.3c-.5-.4-1.1-.7-1.8-.7H5c-.6 0-1.1.4-1.4.9l-1.4 2.9A3.7 3.7 0 0 0 2 12v4c0 .6.4 1 1 1h2"></path>
          <circle cx="7" cy="17" r="2"></circle>
          <path d="M9 17h6"></path>
          <circle cx="17" cy="17" r="2"></circle>
        </svg>
      ),
      description:
        // There is ample parking available at the venue. We recommend arriving at least 30 minutes before the event
        // starts to find a good spot.
        " There is ample parking available at the venue. Gear up for the drive! Discover convenient driving routes and tips to  make your journey to our wedding venue seamless.",
      link: "",
    },
  ];

  function getMapDirectionLink() {
    if (navigator.geolocation && latitude && longitude) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const userLocation =
            position.coords.latitude + "," + position.coords.longitude;
          const destination = `${latitude},${longitude}`; // Replace with your destination coordinates
          const mapUrl =
            "https://www.google.com/maps/dir/" +
            userLocation +
            "/" +
            destination;
          setDirectionLink(mapUrl);
        },
        function () {
          console.log("Error while setup direction");
        }
      );
    } else {
      // Browser doesn't support Geolocation
      console.log("Browser doesn't support Geolocation");
    }
  }

  useEffect(() => {
    getMapDirectionLink();
  }, [latitude, longitude]);

  return (
    <section className="wpo-contact-section-s6 section-padding section">
      <SectionTitle subTitle={"The Wedding Venue"} MainTitle={"How To Reach"} />
      <div className="venueContainer">
        <div className="row">
          <div className="col col-lg-10 offset-lg-1 venueWrapper">
            <Slide direction="up" duration={1000} triggerOnce="true">
              <div className="wpo-contact-section-wrapper venueSection">
                <div className="wpo-contact-form-area">
                  <div className="wpo-contact-section-inner">
                    <div className="addressWrapper">
                      <p className="orangeText address">
                        <i className="fi flaticon-maps-and-flags" />
                        {address}
                        <br />
                        {phone && <i className="fi flaticon-phone-call" />}
                        {phone}
                      </p>
                    </div>
                    {directionLink && (
                      <a
                        href={directionLink}
                        target="_blank"
                        rel="noreferrer"
                        className="orangeText"
                        style={{
                          marginBottom: 20,
                          textDecoration: "underline",
                        }}
                      >
                        See Direction
                      </a>
                    )}
                    <div className="optionContainer">
                      {TransportOpt.map((t, i) => {
                        const index = (i + 1) * 200;
                        return (
                          <div style={{ marginBottom: 20 }}>
                            <div className="option">
                              {t.icon}
                              <h3>{t.title}</h3>
                            </div>
                            <p>{t.description}</p>
                            {directionLink && (
                              <a
                                href={`${directionLink}${t.link}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                Checkout{" "}
                                {/* <i className="fi flaticon-right-arrow"></i> */}
                                <svg
                                  className="w-5 h-5 ml-1"
                                  height="20"
                                  width="20"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                                    clip-rule="evenodd"
                                  ></path>
                                </svg>
                              </a>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Slide>
          </div>
        </div>
      </div>
      <div className="wpo-contact-map">
        <iframe
          title="location"
          className="map"
          style={{ overflow: "auto" }}
          src={`https://maps.google.com/maps?q=${latitude}, ${longitude}&z= 15&output=embed`}
          width="360"
          height="270"
          frameborder="0"
          scrolling="yes"
        ></iframe>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    venue: state.inviteDetails.invite?.venue,
  };
};

export default connect(mapStateToProps)(VenueSection);
