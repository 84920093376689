import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import ParticlesJson from './Particles.json'
// import { Snow } from "react-snow-particle";
import { confetti } from "tsparticles-confetti";



const MyParticlesComponent = () => {



  // const [showParticles, setShowParticles] = useState(true)

  // useEffect(() => {
  //   setTimeout(() => {
  //     setShowParticles(false)
  //   }, 30000);
  // }, [])

  // return (
  //   showParticles ? <div style={{ background: '#282c34', position: 'relative' }}>
  //     <div style={{
  //       position: 'fixed',
  //       width: '100vw',
  //       height: '100vh',
  //       zIndex: 1
  //     }}>
  //       <Snow backgroundColor="transparent" color="#978191" size="5px" particles={50} />
  //     </div>
  //   </div> : null
  // )
};

export default MyParticlesComponent;