import { ModernChicData } from "../../json/modernChic";
import {
  INVITATION_LOADING,
  SET_INVITATION_DETAILS,
  SET_INVITATION_OPEN,
  SET_INVITATION_PREVIEW,
  SET_INVITATION_THEME,
} from "../actions/type";

const initialState = {
  invite: ModernChicData,
  theme: "classic-elegance",
  loading: true,
};

const inviteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVITATION_DETAILS:
      return {
        ...state,
        invite: action.data,
      };
    case SET_INVITATION_OPEN:
      return {
        ...state,
        isOpen: action.data || false,
      };
    case SET_INVITATION_PREVIEW:
      return {
        ...state,
        isPreview: action.data || false,
      };
    case SET_INVITATION_THEME:
      return {
        ...state,
        theme: action.data || false,
      };
    case INVITATION_LOADING:
      return {
        ...state,
        loading: action.data || false,
      };
    default:
      return state;
  }
};

export default inviteReducer;
