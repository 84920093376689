import React from "react";
import { Slide } from "react-awesome-reveal";
import SectionTitle from "../SectionTitle";

import bshape1 from "../../images/story/flower-shape1.svg";
import bshape2 from "../../images/story/flower-shape2.svg";
import bshape3 from "../../images/story/flower-shape3.svg";
import bshape4 from "../../images/story/flower-shape4.svg";

import { connect } from "react-redux";
import moment from "moment";

const EventSection = (props) => {
  const { events, imageWrapperShape } = props;
  const shape = imageWrapperShape || "";

  return (
    <section
      className="wpo-story-section wpo-event-section section-padding"
      id="story"
    >
      <div className="container">
        <SectionTitle
          subTitle={"The Wedding Events"}
          MainTitle={"When & Where"}
        />
        <div className="wpo-event-wrap">
          <div className="row">
            {events.map((event, eitem) => {
              const animation = 1000 + eitem * 200;
              return (
                <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                  <Slide
                    direction="left"
                    duration={animation}
                    triggerOnce="true"
                  >
                    <div className="wpo-event-item">
                      <div className={`wpo-event-img ${shape}`}>
                        <div className={`wpo-event-img-inner ${shape}`}>
                          <img className={shape} src={event?.photo} alt="" />
                        </div>
                      </div>
                      <div className="wpo-event-text">
                        <div className="title">
                          <h2>{event.name}</h2>
                        </div>
                        <ul>
                          <li>
                            {moment(event?.dateAndTime).format(
                              "dddd, DD MMMM YYYY"
                            )}{" "}
                            <br />{" "}
                            {moment(event?.dateAndTime).format("hh:mm A")}{" "}
                            Onwards
                          </li>
                          <li>{event.theme}</li>
                          <li>{event.venue}</li>
                          {/* <li>
                            <LocationMap />
                          </li> */}
                        </ul>
                      </div>
                    </div>
                  </Slide>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flower-shape-1">
        <img src={bshape1} alt="" />
      </div>
      <div className="flower-shape-2">
        <img src={bshape2} alt="" />
      </div>
      <div className="flower-shape-3">
        <img src={bshape3} alt="" />
      </div>
      <div className="flower-shape-4">
        <img src={bshape4} alt="" />
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    events: state.inviteDetails.invite?.events,
  };
};

export default connect(mapStateToProps)(EventSection);
