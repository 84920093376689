import API from "../api/v2";

export const callInvitationAPI = async (inviteId, isPreview) => {
  try {
    const response = await API.getInvitation(inviteId, isPreview);
    return response;
  } catch (error) {
    console.log("Get invitation API error | ", error);
    return {};
  }
};

export const checkValidityAPI = async (inviteId) => {
  try {
    const body = {
      pathname: inviteId,
    };
    const response = await API.postRequest("/validity/check", body);
    return response.status === 200;
  } catch (error) {
    console.log("Get invitation API error | ", error);
    return false;
  }
};

export const addReview = async (body) => {
  try {
    const response = await API.postRequest(`/add/review`, body);
    return response;
  } catch (error) {
    console.log("Get invitation API error | ", error);
    return false;
  }
};
