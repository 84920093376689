import React from "react";
import { connect } from "react-redux";
import sImg1 from "../../images/wedding-date/1.png";
import sImg2 from "../../images/wedding-date/2.png";
import TimeCountDown from "../countdown";
import { addDaysToDate, getDayDiffFromToday } from "../../utils";
import TypeWriter from "../TypeWriter/TypeWriter";
import moment from "moment";
import { Fade, Slide } from "react-awesome-reveal";

const WeddingDate = ({
  brideName = "",
  groomName = "",
  eventDate = "",
  isPreview,
}) => {
  const brideFname = brideName.split(" ")[0];
  const groomFname = groomName.split(" ")[0];

  const daysLeft = getDayDiffFromToday(eventDate);
  const countDownDate = isPreview ? addDaysToDate(10) : eventDate;

  return (
    <Slide direction="up" delay={100} duration={600} triggerOnce="true">
      <section className="wpo-hero-wedding-date">
        <div className="wpo-wedding-date-inner">
          <span>Save the Date</span>
          <h2>
            {groomFname} & {brideFname}
          </h2>
          <TypeWriter
            weddingDate={moment(countDownDate).format("MMMM DD, YYYY")}
          />

          <span className="shape">
            <img src={sImg1} alt="" />{" "}
          </span>
          {countDownDate && (
            <div className="row">
              <div className="col col-xs-12">
                <div className="clock-grids">
                  <div id="clock">
                    <div id="clock">
                      <TimeCountDown weddingDate={countDownDate} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="shape-1">
          <img src={sImg2} alt="" />
        </div>
        <div className="shape-2">
          <img src={sImg2} alt="" />
        </div>
      </section>
    </Slide>
  );
};

const mapStateToProps = (state) => {
  return {
    brideName: state.inviteDetails.invite?.bride?.name,
    groomName: state.inviteDetails.invite?.groom?.name,
    eventDate: state.inviteDetails.invite?.eventDate,
    isPreview: state.inviteDetails?.isPreview,
  };
};

export default connect(mapStateToProps)(WeddingDate);
