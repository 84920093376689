import React from "react";
import { connect } from "react-redux";
import { getHashtag } from "../../utils/string";
import HashtagImg from "../../images/default/hashtag.png";

const Hashtag = ({ bride = {}, groom = {} }) => {
  const hashtag = getHashtag(bride?.name, groom?.name);

  return (
    <div className="hashtagContainer">
      {/* <img src={HashtagImg}/> */}
      <span className="orangeText mBtm30">{hashtag}</span>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    bride: state.inviteDetails.invite?.bride,
    groom: state.inviteDetails.invite?.groom,
  };
};

export default connect(mapStateToProps)(Hashtag);
