import React, { useState } from "react";
import { Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import shape1 from "../../images/footer-shape-1.svg";
import shape2 from "../../images/footer-shape-2.svg";
import Logo from "../../images/Logo/logo.svg";
import {
  Amantrika_link,
  Email_link,
  instagram_link,
} from "../../utils/constant";
import { addReview } from "../../utils/api";

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const Footer = (props) => {
  const [review, setReview] = useState({
    name: "",
    reviewText: "",
  });

  const [successLabel, showSuccessLabel] = useState(false);

  const onChangeReview = (value) => {
    setReview({ ...review, ...value });
  };

  const onAddReview = async () => {
    const response = await addReview(review);
    if (response?.status === 200) {
      showSuccessLabel(true);
      setTimeout(() => {
        showSuccessLabel(false);
        setReview({
          ...review,
          ...{
            name: "",
            reviewText: "",
          },
        });
      }, 5000);
    }
  };

  const redirectToAmantrikaHome = () => {
    window.open(Amantrika_link, "_blank");
  };

  return (
    <footer className={`wpo-site-footer ${props.footerClass} section`}>
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row align-items-center">
            <div className="col col-xl-4  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="logo widget-title">
                  <img src={Logo} alt="logo" />
                  {/* <Link className='logo' onClick={ClickHandler} to="/">Habibi</Link> */}
                </div>
                <p>Did you like our beautiful invitation?</p>
                <p>
                  Create your perfect invitation with ease on our website today!
                </p>
                <button
                  style={{ backgroundColor: "#e35d5d", color: "white" }}
                  onClick={redirectToAmantrikaHome}
                  target="_blank"
                  className="theme-btn-s2"
                >
                  Create Now
                </button>
              </div>
            </div>

            <div className="col col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget writeReviewWrapper">
                <div className="writeReviewContainer">
                  <p>Write a Review</p>
                  <input
                    value={review.name}
                    className="inputBox"
                    placeholder="Name"
                    onChange={(e) => onChangeReview({ name: e.target.value })}
                  />
                  <textarea
                    value={review.reviewText}
                    name="review"
                    rows={4}
                    className="inputBox"
                    placeholder="Please share your thoughts here..."
                    onChange={(e) =>
                      onChangeReview({ reviewText: e.target.value })
                    }
                  ></textarea>
                  {successLabel && (
                    <span>
                      Thank you for your valuable feedback. Your insights help
                      us improve and succeed!
                    </span>
                  )}
                  <button
                    disabled={review?.name === "" || review?.reviewText === ""}
                    onClick={onAddReview}
                    target="_blank"
                    className="theme-btn-s2 reviewBtn"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>

            <div className="col col-xl-4  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>Links</h3>
                </div>
                <div className="link-wrap">
                  <ul>
                    <li>
                      <a
                        className="footerLink"
                        onClick={ClickHandler}
                        href={`${Amantrika_link}`}
                      >
                        Create Invitation
                      </a>
                    </li>
                    <li>
                      <a
                        className="footerLink"
                        onClick={ClickHandler}
                        href={`${Amantrika_link}/about`}
                      >
                        About Us
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <a className="footerIcons" href={instagram_link} target="_blank">
                <i className="ti-instagram" style={{ marginRight: 20 }}></i>
              </a>
              <a className="footerIcons" href={Email_link} target="_blank">
                <i className="ti-email"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="ft-shape-1">
        <Slide direction="left" duration="1000" triggerOnce="true">
          <img src={shape1} alt="" />
        </Slide>
      </div>
      <div className="ft-shape-2">
        <Slide direction="right" duration="1200" triggerOnce="true">
          <img src={shape2} alt="" />
        </Slide>
      </div>
    </footer>
  );
};

export default Footer;
