import React from "react";

const SectionTitle = (props) => {
  return (
    <div className={`wpo-section-title ${props.s2Class}`}>
      <span style={{ letterSpacing: 2, fontSize: 28 }}>{props.subTitle}</span>
      <p>{props.MainTitle}</p>
    </div>
  );
};

export default SectionTitle;
