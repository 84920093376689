import {
  Amantrika_link,
  // Amantrika_Local_link,
  CloudinaryPathPrefix,
} from "./constant";

function getFlashProducts(products) {
  return products.filter((item) => item.sale === true).slice(0, 8);
}

function getFeaturedProducts(products) {
  return products.filter((item) => item.sale === true).slice(0, 12);
}

function totalPrice(items) {
  return items.reduce((itemAcc, item) => {
    return (itemAcc += item.price * item.qty);
  }, 0);
}

function isWishListed(productId, wishList) {
  return wishList.findIndex((product) => product.id === productId) !== -1;
}

function getCompareList(items) {
  return items.slice(0, 4);
}

function searchFilter(row, search) {
  return row.title.toLowerCase().includes(search.toLowerCase()) || !search;
}

// short helper function
function checkLengNull(data) {
  if (data !== null) {
    return data.length > 0;
  }
  return false;
}

function isEquals(a, b) {
  if (a !== null && b !== null) {
    return a.toLowerCase() === b.toLowerCase();
  }
  return a === b;
}

function minValueOne(qty) {
  if (qty < 1) {
    return 1;
  }
  return qty;
}

// filter function
function filterProductByCategory(product, selected_category) {
  if (checkLengNull(selected_category)) {
    return product.category.toLowerCase() === selected_category.toLowerCase();
  }
  return true;
}

function filterProductByPrice(product, price) {
  if (checkLengNull(price)) {
    return product.price >= price[0] && product.price <= price[1];
  }
  return true;
}

function filterProductByColor(product, color) {
  if (checkLengNull(color)) {
    for (var i = 0; i < product.colors.length; i++) {
      if (product.colors[i].toLowerCase() === color.toLowerCase()) {
        return true;
      }
    }
    return false;
  }
  return true;
}

function filterProductBySize(product, size) {
  if (checkLengNull(size)) {
    for (var i = 0; i < product.size.length; i++) {
      if (product.size[i].toLowerCase() === size.toLowerCase()) {
        return true;
      }
    }
    return false;
  }
  return true;
}

function getDayDiffFromToday(date) {
  const date1 = new Date();
  const date2 = new Date(date);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
}

function addDaysToDate(days) {
  var today = new Date();
  var futureDate = new Date(today);
  futureDate.setDate(today.getDate() + days);
  return futureDate.toDateString();
}

function isEvenIndex(index) {
  return index % 2 === 0;
}

function isPreviewTemplateWithPath(id, path) {
  console.log(document, document.referrer, "===== referrer");
  return (
    id &&
    path?.includes("/preview") &&
    (document.referrer.includes(Amantrika_link) ||
      sessionStorage.getItem("isPreviewWithPath") === "true")
  );
}

function getQueryParamFromUrl(param) {
  const paramArr = param.includes("?") ? param.replace("?", "").split("=") : [];
  if (paramArr.length > 0) {
    return {
      key: paramArr[0],
      value: paramArr[1],
    };
  }
  return {};
}

// Get short address
function getShortAddress(venue = "") {
  let shortAddress = venue;
  const addressArr = venue?.split(",");
  if (addressArr.length > 0) {
    if (addressArr.length === 1) {
      shortAddress = addressArr[addressArr.length - 1];
    } else if (addressArr.length === 2) {
      shortAddress = `${addressArr[addressArr.length - 2]}, ${
        addressArr[addressArr.length - 1]
      }`;
    } else if (addressArr.length >= 3) {
      shortAddress = `${addressArr[addressArr.length - 3]}, ${
        addressArr[addressArr.length - 2]
      }, ${addressArr[addressArr.length - 1]}`;
    } else {
      shortAddress = addressArr.join(" ");
    }
  }
  return shortAddress;
}

export const getCloudinaryFullPath = (path) => {
  return path.includes(CloudinaryPathPrefix)
    ? path
    : `${CloudinaryPathPrefix}${path}`;
};

export {
  getFlashProducts,
  getFeaturedProducts,
  totalPrice,
  isWishListed,
  filterProductByCategory,
  filterProductByPrice,
  filterProductByColor,
  filterProductBySize,
  isEquals,
  minValueOne,
  getCompareList,
  searchFilter,
  getDayDiffFromToday,
  addDaysToDate,
  isEvenIndex,
  isPreviewTemplateWithPath,
  getQueryParamFromUrl,
  getShortAddress,
};
