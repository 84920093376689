import axios from "axios";
// axios.defaults.baseURL = "http://localhost:8081/api";
axios.defaults.baseURL = "https://amantrika-server-phi.vercel.app/api";

class API {
  static getInvitation = async (inviteId, isPreview) => {
    const response = await axios.get(
      `/invite/${inviteId}?isPreview=${isPreview}`
    );
    return response.data;
  };

  static getAllInvitation = async () => {
    const response = await axios.get(`/invites`);
    return response.data;
  };

  static postRequest = async (path, body) => {
    const response = await axios.post(path, body);
    return response.data;
  };
}

export default API;
