import React, { Fragment } from "react";

import { Dialog } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import bg from "../../../images/Banner/marketing-banner.png";
import Logo from "../../../images/Logo/logo.svg";

import "./index.scss";
import { Amantrika_link } from "../../../utils/constant";

const MarketingBanner = ({ maxWidth, onClose }) => {
  return (
    <Fragment>
      <Dialog
        open={true}
        onClose={onClose}
        className="modalWrapper quickview-dialog"
        maxWidth={maxWidth}
      >
        <section class="marketing-banner-container">
          <img src={bg} alt="" />
          <div class="marketing-content">
            <IconButton
              className="event-close-btn"
              aria-label="close"
              onClick={onClose}
            >
              <i className="ti-close"></i>
            </IconButton>
            <img src={Logo} alt="logo" />

            <p class="title">
              Did you like our beautiful <br />
              wedding invitation?
            </p>
            {/* <p class="message">Personalize every detail to reflect <br /> your unique love story with Amantrika</p> */}

            <h2>
              <span class="subtitle">Create your own masterpiece today!</span>
              {/* <span class="discount">Get 20% off</span> */}
            </h2>

            <a
              href={Amantrika_link}
              target="_blank"
              rel="noopener noreferrer"
              class="cta-button"
            >
              Create Now
            </a>

            {/* <p class="offer-info">Offer valid until 24th March, 2021 *</p> */}
          </div>
        </section>
      </Dialog>
    </Fragment>
  );
};
export default MarketingBanner;
