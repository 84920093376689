import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import BG_MUSIC from "../../music/sound.mp3";
import "./style.css";
import { connect } from "react-redux";

const Scrollbar = ({ isInviteOpen }) => {
  const [isPlay, setIsPlay] = useState(isInviteOpen);

  const playMusic = () => {
    setIsPlay(isInviteOpen);
    const audio = document.getElementById("backgroundMusic");
    audio.play();
  };

  const pauseMusic = () => {
    const audio = document.getElementById("backgroundMusic");
    audio.pause();
    setIsPlay(false);
  };

  useEffect(() => {
    isInviteOpen && playMusic();
  }, [isInviteOpen]);

  const handleVisibilityChange = () => {
    if (document?.hidden) {
      pauseMusic();
    } else {
      playMusic();
    }
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return (
    <div className="col-lg-12">
      <div className="header-menu">
        <ul className="smothscroll">
          {/* <li>
            <div
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
              style={{ color: "white" }}
            >
              <AnchorLink href="#scrool">
                <i className="ti-arrow-up"></i>
              </AnchorLink>
            </div>
          </li> */}
          <li>
            {isPlay ? (
              <div onClick={pauseMusic} style={{ color: "white" }}>
                <AnchorLink>
                  <i className="ti-control-pause"></i>
                </AnchorLink>
              </div>
            ) : (
              <div onClick={playMusic} style={{ color: "white" }}>
                <AnchorLink>
                  <i className="ti-control-play"></i>
                </AnchorLink>
              </div>
            )}
          </li>
        </ul>
      </div>
      <audio id="backgroundMusic" loop>
        <source src={BG_MUSIC} type="audio/mpeg" />
      </audio>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isInviteOpen: state.inviteDetails?.isOpen || false,
  };
};

export default connect(mapStateToProps)(Scrollbar);
