import * as types from "./type";
import "react-toastify/dist/ReactToastify.min.css";

// Invitation Methods

export const setInvitationDetails = (data) => (dispatch) => {
  dispatch({
    type: types.SET_INVITATION_DETAILS,
    data,
  });
};

export const setInvitationOpen = (data) => (dispatch) => {
  dispatch({
    type: types.SET_INVITATION_OPEN,
    data,
  });
};

export const setInvitationPreview = (data) => (dispatch) => {
  dispatch({
    type: types.SET_INVITATION_PREVIEW,
    data,
  });
};

export const setInvitationTheme = (data) => (dispatch) => {
  dispatch({
    type: types.SET_INVITATION_THEME,
    data,
  });
};

export const setInvitationLoading = (data) => (dispatch) => {
  dispatch({
    type: types.INVITATION_LOADING,
    data,
  });
};
