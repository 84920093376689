import Lottie from "react-lottie";
import PreloaderJson from "../../json/preloader-final.json";

const Preloader = () => {
  const defaultProps = {
    loop: true,
    autoplay: true,
    animationData: PreloaderJson,
  };

  return (
    <div className="preloader2">
      <Lottie options={defaultProps} height={280} width={280} />
    </div>
  );
};

export default Preloader;
