import React, { useRef, useEffect, useState } from "react";
// import Navbar from "../../components/Navbar/Navbar";
import Hero4 from "../../components/hero4/hero4";
// import PartnerSection from "../../components/PartnerSection";
import CoupleSection4 from "../../components/CoupleSection4/CoupleSection4";
// import VideoSection from "../../components/VideoSection";
// import StorySection4 from "../../components/StorySection4/StorySection4";
// import PortfolioSectionS2 from "../../components/PortfolioSectionS2/PortfolioSectionS2";
// import BrideGrooms2 from "../../components/BrideGrooms2";
// import RsvpSectionS3 from "../../components/RsvpSectionS3/RsvpSectionS3";
import EventSectionS3 from "../../components/EventSection3/EventSection3";
// import BlogSection from "../../components/BlogSection/BlogSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
// import vImg from "../../images/video-img/img-2.jpg";
// import bg from "../../images/rsvp/bg.jpg";
import VenueSection from "../../components/VenueSection/VenueSection";
import InviteeSection from "../../components/InviteeSection/InviteeSection";
import EventSection4 from "../../components/EventSection4/EventSection4";
import QuoteSection from "../../components/QuoteSection/QuoteSection";
import useScrollDirection from "../../hooks/useScrollDirection";

const HomePage4 = () => {
  // const [snapEnabled, setSnapEnabled] = useState(true);
  const containerRef = useRef();
  // const scrollDirection = useScrollDirection(containerRef);

  // useEffect(() => {
  //   if (scrollDirection === "down") {
  //     setSnapEnabled(true);
  //   } else {
  //     setSnapEnabled(false);
  //   }
  // }, [scrollDirection]);

  return (
    <div ref={containerRef} className={`fadeInSlow scroll-container`}>
      {/* <Navbar hclass={"wpo-header-style-3"} /> */}
      <Hero4 />
      {/* <PartnerSection /> */}
      <CoupleSection4 />
      {/* <VideoSection vimg={vImg} /> */}
      {/* <PortfolioSectionS2 /> */}
      {/* <BrideGrooms2 /> */}
      {/* <EventSectionS3 /> */}
      <QuoteSection />
      <EventSection4 haveBackground={false} />
      <VenueSection />
      <InviteeSection haveBackground={false} />
      {/* <RsvpSectionS3 bg={bg} /> */}
      {/* <BlogSection bgClass={"section-bg"} /> */}
      <Footer footerClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </div>
  );
};

export default HomePage4;
