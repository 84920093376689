import React from "react";
import { Link } from 'react-router-dom'
import hero1 from '../../images/hero/home-2.jpg'
import hero2 from '../../images/hero/home-1.jpg'
import vector from '../../images/slider/vector.svg'
import vector2 from '../../images/slider/shape-vector.png'
import Logo from '../../images/Logo/logo.svg'
import coupleImg4 from "../../images/couple/right-invitee.svg";
import { Amantrika_link } from "../../utils/constant";


const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const AmantrikaHome = () => {
    return (
        <section className="static-hero-s7 amantrika-home">
            <div className="hero-container">
                <div className="hero-inner">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col col-xl-4 col-lg-4 col-12">
                                <div className="wpo-static-hero-inner">
                                    <div className="slide-sub-title">
                                        <img src={Logo} alt="Logo" />
                                    </div>
                                    <div>
                                        <h2 className="fontJost title">Your wedding countdown starts from here.</h2>
                                    </div>
                                    <div data-swiper-parallax="400" className="slide-text">
                                        <p>Best wishes to your big day of life. Congratulation its your wedding time. We
                                            are happy to see you here. We must want that your dreamy wedding will made
                                            with us.</p>
                                        <p>Join us in crafting the wedding invitation of your dreams together. Let's create unforgettable moments for your special day.</p>
                                    </div>
                                    <div className="shape">
                                        <a onClick={ClickHandler} href={Amantrika_link} target="_blank" className="theme-btn-s2 amantrika-btn">Create Now</a>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                                <div className="right-shape">
                                    <img src={coupleImg4} alt="" />
                                </div>
                            </div>
                            <div className="col col-xl-8 col-lg-8 col-12">
                                <div className="wpo-hero-img-wrap">
                                    <div className="wpo-hero-img-item">
                                        <div className="wpo-hero-img" >
                                            <img src={hero1} alt="" className="img-1" />
                                        </div>
                                    </div>
                                    <div className="wpo-hero-img-item">
                                        <div className="wpo-hero-img">
                                            <img src={hero2} alt="" />
                                        </div>
                                    </div>
                                    <div className="wpo-hero-img-topper">
                                        <div className="wpo-hero-img-topper-inner amantrika-color" style={{ background: "#bb5e5e" }}>
                                            <span>
                                                <img src={vector} alt="" />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-vector">
                <img src={vector2} alt="" />
            </div>
        </section>
    )
}

export default AmantrikaHome;