import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";

const TypeWriter = ({ weddingDate }) => {
  const [dateMessage, setDateMessage] = useState("");

  useEffect(() => {
    setDateMessage(`We Are Getting Married on ${weddingDate}`);
  }, [weddingDate]);

  return (
    <div
      className="slide-text"
      style={{ display: "flex", justifyContent: "center" }}
    >
      {dateMessage !== "" && (
        <p className="type-writer">
          <Fade
            className="type-animate"
            delay={100}
            cascade
            damping={1e-1}
            triggerOnce
          >
            {dateMessage}
          </Fade>
        </p>
      )}
    </div>
  );
};

export default TypeWriter;
