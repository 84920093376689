import { connect } from "react-redux";
import { getCloudinaryFullPath } from "../../utils";
import { Fade } from "react-awesome-reveal";

const QuoteSection = ({ quote, quotePhoto }) => {
  return (
    <div className="quoteContainer section-padding">
      <div className="content section">
        <div className="photos">
          <div className="thumbnails">
            <img src={getCloudinaryFullPath(quotePhoto)} alt="quote-bg" />
            <div className="black"></div>
            <div className="title">
              <div>
                <i className="fi flaticon-quotation"></i>
              </div>
              <Fade
                className="animate__animated animate__fadeInLeft"
                delay={100}
                duration={800}
                triggerOnce="true"
              >
                <div style={{ margin: 5 }}>
                  {quote ||
                    "Love is the essence that binds two hearts, and marriage is the celebration that brings those hearts together forever."}
                </div>
                <div>
                  <i className="fi flaticon-quotation"></i>
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    quotePhoto: state.inviteDetails.invite?.quotePhoto,
    quote: state.inviteDetails.invite?.quote,
  };
};

export default connect(mapStateToProps)(QuoteSection);
